<template>
  <section class="lg:px-10 lg:py-11 p-4 bg-white rounded-xl">
    <div class="mb-10">
      <p class="text-brand-black font-normal text-3xl font-cooper">Verify email address</p>
      <p class="text-brand-black/40 text-base font-normal grid">
        <span class="">We’ve just sent a 6 digit code to {{ email }}</span>
        <span>Check your email. Enter the code to continue</span>
      </p>
      <p class="text text-brand-black/75">
        <resend-otp v-if="canResendOtp" @resend="triggerRequestOtp" />
        <vue-countdown v-if="!canResendOtp" :time="secondsToResend" v-slot="{ minutes, seconds }" @end="canResendOtp = true">
          Resend in {{ minutes }}:{{ seconds }}
        </vue-countdown>
      </p>
      <div class="mt-2">
        <vue-countdown :time="secondsToExpire" v-slot="{ minutes, seconds }" @end="otpExpired">
          OTP code expires in - {{ minutes }}m : {{ seconds }}s
        </vue-countdown>
      </div>
      <div class="mt-2">
        <span>Kindly check your spam folder too, and mark as not spam if there.</span>
      </div>
    </div>
    <form class="">
      <div style="display: flex; flex-direction: row; justify-content: center">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
          :placeholder="['*', '*', '*', '*', '*', '*']"
          @on-change="handleOnChange"
          @on-complete="handleOnChange"
        />
      </div>
      <div class="pt-4 space-x-2 md:space-x-4">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
          <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError"> </VueRecaptcha>
        </div>
      </div>

      <div class="mt-2">
        <Button label="Finish" color="primary" full @click="submit" :loading="loading" :disabled="disabled" />
      </div>
    </form>
  </section>
</template>

<script setup>
import ResendOtp from '@/components/ResendOtp.vue';
import Button from '@/components/Button.vue';
import { alert, errorMessage } from '@/utils/helper';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { VueRecaptcha } from 'vue-recaptcha';

const otpInput = ref('');
const otp = ref('');
const disabled = ref(true);
const loading = ref(false);
const store = useStore();
const router = useRouter();
const canResendOtp = ref(false);
const secondsToExpire = ref(580990);
const secondsToResend = ref(Math.round(1.2 * 100000));
const captcha = ref('');
const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_KEY);

const email = computed(() => store.getters['auth/userDetails?.email']);

const handleError = () => {};

const handleSuccess = (response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(response);
  }
  captcha.value = response;
};

const handleOnChange = (value) => {
  if (value.length >= 6) {
    disabled.value = false;
    otp.value = value;
  }
};

const submit = async () => {
  try {
    loading.value = true;
    await store.dispatch('auth/verifyOtp', {
      otp: `${otp.value}`,
      captcha: captcha.value,
    });
    const path = store.getters['global/getRedirectUrl'];
    router.push(path || '/onboard');
  } catch (error) {
    loading.value = false;
    if (error.response && error.response.status === 422) {
      console.log(error.response.data.errors);
    }
    alert(errorMessage(error), 'error');
  }
};

async function otpExpired() {
  alert('Your otp code has expired. Use the resend link to get a new one', 'info');
}

const triggerRequestOtp = async() => {
  canResendOtp.value = false;
  secondsToResend.value = Math.round(secondsToResend.value * 1.7);
  await requestOtp();
  secondsToExpire.value = 580990;
};

const requestOtp = async () => {
  try {
    await store.dispatch('auth/requestOtp', {email: email?.value});
    alert('A new OTP has been sent to your mail', 'success', 4000);
  } catch (error) {
    alert(errorMessage(error) || 'Something went wrong (Please check internent connection)', 'error', 4000);
  }
};
</script>
